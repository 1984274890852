var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    { ref: "emergency_contacts" },
    [
      _c("form-section", {
        attrs: {
          sectionTitle: _vm.$t("applications.emergency-contact"),
          wideFirstCol: ""
        },
        scopedSlots: _vm._u([
          {
            key: "col-1",
            fn: function() {
              return _vm._l(_vm.entitiesData, function(contact) {
                return _c(
                  "div",
                  { key: contact.id },
                  [
                    _c("control-tool-bar", {
                      attrs: {
                        id: contact.id,
                        title: _vm.$t("applications.emergency-contact")
                      },
                      on: {
                        remove: function($event) {
                          return _vm.removeEntity(contact.id)
                        }
                      }
                    }),
                    _c("applicant-emergency-contact", {
                      attrs: { modelData: contact },
                      on: {
                        update: function($event) {
                          return _vm.update(contact.id, "root", $event)
                        }
                      }
                    })
                  ],
                  1
                )
              })
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
    <v-form ref="emergency_contacts">
        <form-section
            :sectionTitle="$t('applications.emergency-contact')"
            wideFirstCol
        >
            <template v-slot:col-1>
                <div v-for="contact in entitiesData" :key="contact.id">
                    <control-tool-bar
                        :id="contact.id"
                        :title="$t('applications.emergency-contact')"
                        @remove="removeEntity(contact.id)"
                    ></control-tool-bar>
                    <applicant-emergency-contact
                        :modelData="contact"
                        @update="update(contact.id, 'root', $event)"
                    ></applicant-emergency-contact>
                </div>
            </template>
        </form-section>
    </v-form>
</template>

<script>
import InteractsWithEntities from "@components/application/mixins/InteractsWithEntities";

export default {
    name: "applicant-emergency-contacts",
    components: {
        ApplicantEmergencyContact: () => import("./ApplicantEmergencyContact"),
        ControlToolBar: () => import("./ControlToolBar"),
    },
    mixins: [InteractsWithEntities],
    data() {
        return {
            entityIdKey: "id",
            name: this.$t("applications.emergency-contact"),
        };
    },
    methods: {
        validate() {
            return this.$refs["emergency_contacts"].validate();
        },
    },
};
</script>

<style scoped>
</style>